const SocialLinks = () => (
  <div className="social-links">
    <a
      target="_blank"
      href="https://www.instagram.com/andriantam/"
      className="instagram"
      rel="noreferrer"
    >
      <i className="bx bxl-instagram"></i>
    </a>
    <a
      target="_blank"
      href="https://www.facebook.com/im.andriantam"
      className="facebook"
      rel="noreferrer"
    >
      <i className="bx bxl-facebook"></i>
    </a>
    <a
      target="_blank"
      href="https://wa.me/+60122954950"
      className="whatsapp"
      rel="noreferrer"
    >
      <i className="bx bxl-whatsapp"></i>
    </a>
    <a
      target="_blank"
      href="https://www.linkedin.com/in/andriantam/"
      className="linkedin"
      rel="noreferrer"
    >
      <i className="bx bxl-linkedin"></i>
    </a>
    <a
      target="_blank"
      href="https://www.youtube.com/@AndrianTam"
      className="youtube"
      rel="noreferrer"
    >
      <i className="bx bxl-youtube"></i>
    </a>
    <a
      target="_blank"
      href="https://www.twitch.tv/andriantam"
      className="twitch"
      rel="noreferrer"
    >
      <i className="bx bxl-twitch"></i>
    </a>
  </div>
);

export default SocialLinks;
