import { useEffect, useState } from "react";
import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";

import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";

import "./web-development.style.scss";
import { getAllArticles } from "../../controllers/getArticles";
import useLoader from "../../hooks/useLoader";
import { getBehanceProjects } from "../../controllers/getBehance";

const updateDate = (date: number) => {
  return new Date(date * 1000).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const WebDevelopment = () => {
  const { setLoader, hideLoader } = useLoader();
  const [projects, setProjects] = useState<any[]>([]);
  useEffect(() => {
    document.title = `andriantam | Projects`;
    setLoader();
    getBehanceProjects().then(({ projects }) => {
      setProjects(projects);
      hideLoader();
    });
  }, []);

  return (
    <>
      <Header isPortfolio={true} />
      <main id="main">
        <section id="development-hero">
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>Web Development</h2>
          </div>
        </section>

        <Breadcrumbs secondLevelTitle="Web Development" />
        <section id="projects" className="projects">
          <div className="container">
            {projects.length > 0 &&
              projects.map((project): any => (
                <a
                  className="project"
                  href={`/project/${project.id}`}
                  key={project.id}
                >
                  <div className="title">
                    <h4>{project.name}</h4>
                    <p>{updateDate(project.published_on)}</p>
                  </div>
                  <div className="image-wrapper">
                    <LazyLoadImage
                      src={project.covers.original}
                      alt={project.covers.original}
                      effect="blur"
                    />
                  </div>
                </a>
              ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default WebDevelopment;
