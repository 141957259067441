import andriantamLogo from "../assets/img/andriantam-light-small.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SocialLinks from "./social-links";

const Footer = () => {
  return (
    <footer id="contact">
      <div className="container">
        <LazyLoadImage
          src={andriantamLogo}
          className="andriantam-logo"
          alt=""
          effect="blur"
        />
        <p className="tagline">
          Moments are not just meant to be captured,
          <br />
          but to be experienced
        </p>
        <SocialLinks />
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>andriantam</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          <a href="/privacy">Privacy Policy</a>
          <br />
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          <br />
          Built on ReactJS by andriantam
          <br />
          Photos are all owned by andriantam
          <br />
          Part of Toge Business Solutions <br />
          202403168509 (RA0114024-P)
        </div>
      </div>
    </footer>
  );
};

export default Footer;
