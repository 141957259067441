import { useEffect, useState } from "react";
import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";

import "./web-development.style.scss";
import useLoader from "../../hooks/useLoader";
import { NAV } from "../../constants/paths";
import { getBehanceProject } from "../../controllers/getBehance";

const Project = () => {
  const { setLoader, hideLoader } = useLoader();
  let { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setproject] = useState<any>({
    title: "",
    modules: [],
  });
  useEffect(() => {
    document.title = `andriantam | Project`;
    setLoader();
    if (!projectId) navigate("/web-development");
    projectId &&
      getBehanceProject(projectId)
        .then(({ project }) => {
          console.log(project);

          setproject(project);
          hideLoader();
          document.title = `andriantam | ${project.name}`;
        })
        .catch(() => {
          navigate("/web-development");
        });
  }, []);
  return (
    <>
      <Header isPortfolio={true} />
      <main id="main">
        <section
          id="development-hero"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${
              project?.covers
                ? project.covers.original
                : "/assets/img/project-header.jpg"
            }")`,
            backgroundPosition: `center 30%`,
          }}
        >
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>{project.name}</h2>
          </div>
        </section>

        <Breadcrumbs
          secondLevelTitle="Web Development"
          secondLevelUrl={NAV.WEB_DEVELOPMENT}
          thirdLevelTitle={project.name}
          isArticle
        />
        <section id="project" className="project">
          <div className="container">
            {project &&
              project.modules.length > 0 &&
              project.modules.map((module: any) => {
                if (module.type === "image") {
                  return (
                    <LazyLoadImage
                      className="project-image"
                      src={module.sizes[1400]}
                      alt={module.alt_text}
                      key={module.id}
                      effect="blur"
                    />
                  );
                }
                if (module.type === "text") {
                  return (
                    <div
                      className="project-content"
                      dangerouslySetInnerHTML={{ __html: module.text }}
                      key={module.id}
                    />
                  );
                }
              })}
            <div
              className="project-content"
              dangerouslySetInnerHTML={{ __html: project.content }}
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default Project;
