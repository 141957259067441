export const NAV = {
  HOME: "/",
  ABOUT: "/#about",
  SERVICES: "/#services",
  REVIEWS: "/#testimonials",
  RESUME: "/resume",
  PORTFOLIO: "/portfolio",
  ARTICLES: "/articles",
  ARTICLE: "/article",
  WEB_DEVELOPMENT: "/web-development",
  PROJECT: "/project",
  CONTACT: "/#contact",
  PRIVACY: "/privacy",
};
