export const PHOTO_CATEGORIES = {
  LOVE: {
    id: "love",
    flickrId: "72177720314153115",
    title: "Wedding Photography",
    headerUrl: "/assets/img/portfolio/love-header.jpg",
  },
  AUTOMOTIVE: {
    id: "automotive",
    flickrId: "72177720311827518",
    title: "Automotive & Motorsports",
    headerUrl: "/assets/img/portfolio/automotive-header.jpg",
  },
  EVENTS: {
    id: "events",
    flickrId: "72177720318039087",
    title: "Events",
    headerUrl: "/assets/img/portfolio/events-header.jpg",
  },
  PRODUCTS: {
    id: "products",
    flickrId: "72177720311813820",
    title: "Products",
    headerUrl: "/assets/img/portfolio/product-header.jpg",
  },
  TRAVEL: {
    id: "travel",
    flickrId: "72177720311814927",
    title: "Travel",
    headerUrl: "/assets/img/portfolio/travel-header.jpg",
  },
  FNB: {
    id: "fnb",
    flickrId: "72177720315449027",
    title: "FnB",
    headerUrl: "/assets/img/portfolio/food-header.jpg",
  },
};
