import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";
import { InstagramEmbed } from "react-social-media-embed";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";
import "./portfolio.style.scss";
import { NAV } from "../../constants/paths";
import { useEffect } from "react";

const Videography = () => {
  useEffect(() => {
    document.title = `andriantam | Videography`;
  }, []);
  return (
    <>
      <Header isPortfolio={true} />
      <main id="main">
        <section
          id="portfolio-hero"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/img/testimonials-bg.jpg")`,
            backgroundPosition: `center 40%`,
          }}
        >
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>Videography</h2>
          </div>
        </section>

        <Breadcrumbs
          secondLevelTitle="Portfolio"
          secondLevelUrl={NAV.PORTFOLIO}
          thirdLevelTitle="Videography"
        />
        <section>
          <div className="container position-relative">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                <div className="masonry-photo-frame-video">
                  <InstagramEmbed
                    url="https://www.instagram.com/p/DAncNeGSL6M/"
                    width={"100%"}
                  />
                </div>
                <div className="masonry-photo-frame-video">
                  <InstagramEmbed
                    url="https://www.instagram.com/p/DAXfGWFyb3M/"
                    width={"100%"}
                  />
                </div>
                <div className="masonry-photo-frame-video">
                  <InstagramEmbed
                    url="https://www.instagram.com/p/C-XjDedSN2s/"
                    width={"100%"}
                  />
                </div>
                <div className="masonry-photo-frame-video">
                  <iframe
                    src="https://www.youtube.com/embed/sWHo1jPGBUk"
                    title="Cant get over Malaysia's RWB build #5 from Nakai san putting on the Nankang CR-S"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>
                <div className="masonry-photo-frame-video">
                  <iframe
                    src="https://www.youtube.com/embed/oPbv3UOrMOc"
                    title="Nak Semi Slick Mampu Milik ? Cari Nankang Motorsport Malaysia"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>

                <div className="masonry-photo-frame-video">
                  <iframe
                    src="https://www.youtube.com/embed/sgp8TpJeKnc"
                    title="GRA X YSKhong Night Drive (Grass Racing Autosports)"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>

                <div className="masonry-photo-frame-video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/6JyDzT9_miQ?si=aGwJ6LwaqslCfYWi"
                    title="YouTube video player"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>

                <div className="masonry-photo-frame-video">
                  <iframe
                    src="https://www.youtube.com/embed/pXDY4W_u1g4"
                    title="Toyota Celica GT Four cinematic shots"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>

                <div className="masonry-photo-frame-video">
                  <iframe
                    src="https://www.youtube.com/embed/P2SE2FPa5l0"
                    title="New place to wine and dine in PJ 🍷😌"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>
                <div className="masonry-photo-frame-video">
                  <iframe
                    width="474"
                    height="842"
                    src="https://www.youtube.com/embed/iUhi7ICTSWs"
                    title="Just testing out the DJI RS3 mini"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>

                <div className="masonry-photo-frame-video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/8WgRostY4Ko?si=G1ccwwsj44k4X-8D"
                    title="YouTube video player"
                    data-frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    data-allowfullscreen="allowFullScreen"
                  ></iframe>
                </div>
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </section>
      </main>
    </>
  );
};

export default Videography;
