import axios from "axios";

export const getAllArticles = (): Promise<any> => {
  return axios
    .get(
      "https://www.googleapis.com/blogger/v3/blogs/1240370190004187919/posts?key=AIzaSyBX5QU2oHTyEoNteFW07KMoQ_OK90rPCbg&fetchImages=true"
    )
    .then(({ data: { items } }) => items)
    .catch((err) => err);
};

export const getArticle = (id: string): Promise<any> => {
  return axios
    .get(
      `https://www.googleapis.com/blogger/v3/blogs/1240370190004187919/posts/${id}?key=AIzaSyBX5QU2oHTyEoNteFW07KMoQ_OK90rPCbg&fetchImages=true`
    )
    .then(({ data }) => data)
    .catch((err) => err);
};
