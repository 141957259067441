import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import { TESTIMONIES } from "../constants/testimonials";

const Testimonials = () => {
  const setInnerHtml = (content: string) => {
    return { __html: content };
  };
  return (
    <section id="testimonials" className="testimonials">
      <div className="container position-relative">
        <div className="section-title">
          {/* <span>Testimonials and Reviews</span> */}
          <h2>Testimonials and Reviews</h2>
          <p>Reviews of andrian tam</p>
        </div>
        <Swiper
          speed={600}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Navigation, Pagination]}
        >
          {TESTIMONIES.map((testimony): any => (
            <SwiperSlide key={testimony.name}>
              <div className="testimonial-item">
                <div className="testimonial-image">
                  <LazyLoadImage
                    src={testimony.image}
                    className="testimonial-img"
                    alt=""
                    effect="blur"
                  />
                </div>

                <h3>{testimony.name}</h3>
                <h4>{testimony.purpose}</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  <span
                    dangerouslySetInnerHTML={setInnerHtml(
                      testimony.description
                    )}
                  ></span>
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
