import andriantamLogo from "../assets/img/andriantam-light-small.png";
import togeLifestyleLogo from "../assets/img/brands/toge-lifestyle-logo.png";
import nankangLogo from "../assets/img/brands/nankang.png";
import takumiLogo from "../assets/img/brands/takumi.png";
import tamsUpLogo from "../assets/img/brands/tams-up.png";
import togeWagonsLogo from "../assets/img/brands/togewagons.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SocialLinks from "./social-links";

const Hero = () => (
  <section id="hero">
    <div className="hero-container">
      <LazyLoadImage
        src={andriantamLogo}
        className="andriantam-logo"
        alt=""
        effect="blur"
      />
      <h2>
        Moments are not just meant to be captured,
        <br />
        but to be experienced
      </h2>
      <div>
        <SocialLinks />
      </div>
      <a href="#about" className="btn-scroll scrollto" title="Scroll Down">
        <i className="bx bx-chevron-down"></i>
      </a>
      <div className="brands container">
        <div className="row">
          <div className="brand-img">
            <a href="https://www.instagram.com/togelifestyle" target="blank">
              <LazyLoadImage
                src={togeLifestyleLogo}
                className="andriantam-logo"
                alt=""
                effect="blur"
              />
            </a>
          </div>
          <div className="brand-img">
            <a href="https://www.youtube.com/@AndrianTam" target="blank">
              <LazyLoadImage
                src={tamsUpLogo}
                className="andriantam-logo"
                alt=""
                effect="blur"
              />
            </a>
          </div>
          <div className="brand-img">
            <a href="https://www.instagram.com/togewagons" target="blank">
              <LazyLoadImage
                src={togeWagonsLogo}
                className="andriantam-logo"
                alt=""
                effect="blur"
              />
            </a>
          </div>
          <div className="brand-img">
            <a
              href="https://www.instagram.com/nankang.motorsport.malaysia"
              target="blank"
            >
              <LazyLoadImage
                src={nankangLogo}
                className="nankang-logo"
                alt=""
                effect="blur"
              />
            </a>
          </div>
          <div className="brand-img">
            <a
              href="https://www.instagram.com/takumi_motor_oil_malaysia"
              target="blank"
            >
              <LazyLoadImage
                src={takumiLogo}
                className="takumi-logo"
                alt=""
                effect="blur"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
