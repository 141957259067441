import { useEffect } from "react";
import Header from "../components/header";
import Hero from "../components/hero";
import AboutMe from "../components/about-me";
import Services from "../components/services";
import Testimonials from "../components/testimonials";

const Root = () => {
  useEffect(() => {
    document.title = "andriantam | Photographer and videographer | Andrian Tam";
  }, []);
  return (
    <div className="App">
      <Header isRoot={true} />
      <div>
        <Hero />

        <main id="main">
          {/* <AboutMe /> */}
          <Services />
          <Testimonials />
        </main>

        <a
          href="#"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
};

export default Root;
