import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../assets/img/andriantam-light-small.png";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import { NAV } from "../constants/paths";

const NotFound = () => {
  return (
    <>
      <Header />
      <main id="main">
        <section className="general-hero">
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>Not Found</h2>
          </div>
        </section>

        <Breadcrumbs secondLevelTitle="Not Found" />
        <section>
          <div className="container">
            <h4>The page you're looking for does not exist</h4>
            <p>
              Return to <a href={NAV.HOME}>Home</a>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default NotFound;
