import { createContext, useState } from "react";

import Loader from "./components/loader/loader";

export const LoaderContext = createContext({
  hideLoader: () => {},
  setLoader: () => {},
});

const LoaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const setLoader = () => setShowLoader(true);
  const hideLoader = () => setShowLoader(false);

  return (
    <LoaderContext.Provider value={{ hideLoader, setLoader }}>
      {children}
      {showLoader && <Loader />}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
