import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";
import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";

import "./article.style.scss";
import { getAllArticles } from "../../controllers/getArticles";
import useLoader from "../../hooks/useLoader";

const updateDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const Articles = () => {
  const { setLoader, hideLoader } = useLoader();
  const [articles, setArticles] = useState<any[]>([]);
  useEffect(() => {
    document.title = `andriantam | Articles`;
    setLoader();
    getAllArticles().then((response) => {
      setArticles(response);
      hideLoader();
    });
  }, []);

  return (
    <>
      <Header isArticles={true} />
      <main id="main">
        <section id="article-hero">
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>Articles</h2>
          </div>
        </section>

        <Breadcrumbs secondLevelTitle="Articles" />
        <section id="articles" className="articles">
          <div className="container">
            {articles.length > 0 &&
              articles.map((article): any => (
                <a
                  className="article"
                  href={`/article/${article.id}`}
                  key={article.id}
                >
                  <div className="title">
                    <h4>{article.title}</h4>
                    <p>{updateDate(article.updated)}</p>
                  </div>
                  <div className="image-wrapper">
                    <LazyLoadImage
                      src={article.images[0].url}
                      alt={article.title}
                      effect="blur"
                    />
                  </div>
                </a>
              ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default Articles;
