interface BreadcrumbTypes {
  secondLevelTitle: string;
  secondLevelUrl?: string;
  thirdLevelTitle?: string;
  isArticle?: boolean;
}

const Breadcrumbs = ({
  secondLevelTitle,
  secondLevelUrl = "/",
  thirdLevelTitle = undefined,
  isArticle = false,
}: BreadcrumbTypes) => (
  <section className="breadcrumbs">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        {!thirdLevelTitle ? (
          <>
            <h2>{secondLevelTitle}</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>{secondLevelTitle}</li>
            </ol>
          </>
        ) : (
          <>
            <h2>{!isArticle && thirdLevelTitle}</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href={secondLevelUrl}>{secondLevelTitle}</a>
              </li>
              <li>{thirdLevelTitle}</li>
            </ol>
          </>
        )}
      </div>
    </div>
  </section>
);

export default Breadcrumbs;
