import { useRef, useState, useEffect } from "react";
import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";

import "./portfolio.style.scss";
import Isotope from "isotope-layout";
import useLoader from "../../hooks/useLoader";

const PortfolioHome = () => {
  // init one ref to store the future isotope object
  const isotope = useRef<Isotope | null>();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [allLoaded, setAllLoaded] = useState(false);
  const { setLoader, hideLoader } = useLoader();
  let loadedImages = 0;

  // needed to re-render masonry
  function checkAllLoaded() {
    loadedImages++;
    if (loadedImages === 6) {
      // update this once there are more images
      hideLoader();
      setAllLoaded(true);
    }
  }

  // initialize an Isotope object with configs
  useEffect(() => {
    document.title = "andriantam | Portfolio";
    setLoader();
    isotope.current = new Isotope(".filter-container", {
      itemSelector: ".filter-item",
      masonry: {
        columnWidth: ".grid-sizer",
      },
    });
    // cleanup
    hideLoader();
    return () => isotope.current?.destroy();
  }, [allLoaded]);

  // handling filter key change
  useEffect(() => {
    if (filterKey === "*") isotope.current?.arrange({ filter: `*` });
    else isotope.current?.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key: string) => () => setFilterKey(key);
  return (
    <>
      <Header isPortfolio={true} />
      <main id="main">
        <section id="portfolio-hero">
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>Portfolio</h2>
          </div>
        </section>

        <Breadcrumbs secondLevelTitle="Portfolio" />
        <section id="portfolio" className="portfolio">
          <div className="container">
            <div className="section-title">
              <span>My Portfolio</span>
              <h2>My Portfolio</h2>
              <p>Please select which category would you like to view.</p>
            </div>

            <ul id="portfolio-flters" className="d-flex justify-content-center">
              <li
                onClick={handleFilterKeyChange("*")}
                className={`${filterKey === "*" && "filter-active"} `}
              >
                All
              </li>
              <li
                onClick={handleFilterKeyChange("filter-photography")}
                className={`${
                  filterKey === "filter-photography" && "filter-active"
                } `}
              >
                Photography
              </li>
              <li
                onClick={handleFilterKeyChange("filter-video")}
                className={`${
                  filterKey === "filter-video" && "filter-active"
                } `}
              >
                Video
              </li>
              {/* <li
                onClick={handleFilterKeyChange("filter-web")}
                className={`${filterKey === "filter-web" && "filter-active"} `}
              >
                Web Development
              </li> */}
            </ul>

            <div className=" filter-container">
              <div className="grid-sizer"></div>
              <div className=" portfolio-item filter-item filter-photography">
                <a href="/portfolio/events">
                  <div className="portfolio-img">
                    <img
                      src="https://farm66.staticflickr.com/65535/53247478596_5489993475_c.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Photography</p>
                  </div>
                </a>
              </div>

              <div className=" portfolio-item filter-item filter-photography">
                <a href="/portfolio/automotive">
                  <div className="portfolio-img">
                    <img
                      src="https://farm66.staticflickr.com/65535/53471521669_cddbe23a31_c.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Automotive &amp; Motorsports</h4>
                    <p>Photography</p>
                  </div>
                </a>
              </div>

              <div className=" portfolio-item filter-item filter-video">
                <a href="/portfolio/videography">
                  <div className="portfolio-img">
                    <img
                      src="assets/img/testimonials-bg.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Video</h4>
                    <p>Videography</p>
                  </div>
                </a>
              </div>

              <div className=" portfolio-item filter-item filter-photography">
                <a href="/portfolio/love">
                  <div className="portfolio-img">
                    <img
                      src="https://farm66.staticflickr.com/65535/53471505184_13eb1261c6_c.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Wedding Photography</h4>
                    <p>Photography</p>
                  </div>
                </a>
              </div>

              <div className=" portfolio-item filter-item filter-photography">
                <a href="/portfolio/fnb">
                  <div className="portfolio-img">
                    <img
                      src="https://farm66.staticflickr.com/65535/53588848688_62c34d39cd_c.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Food & Beverages</h4>
                    <p>Photography</p>
                  </div>
                </a>
              </div>

              <div className=" portfolio-item filter-item filter-photography">
                <a href="/portfolio/products">
                  <div className="portfolio-img">
                    <img
                      src="https://farm66.staticflickr.com/65535/53247479196_5e488f5ed4_c.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Products</h4>
                    <p>Photography</p>
                  </div>
                </a>
              </div>

              <div className=" portfolio-item filter-item filter-photography">
                <a href="/portfolio/travel">
                  <div className="portfolio-img">
                    <img
                      src="https://farm66.staticflickr.com/65535/50571758301_89e2830679_c.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Travel</h4>
                    <p>Photography</p>
                  </div>
                </a>
              </div>

              {/* <div className=" portfolio-item filter-item filter-web">
                <a href="/portfolio/web-development">
                  <div className="portfolio-img">
                    <img
                      src="assets/img/laptop.jpg"
                      className="img-fluid"
                      alt=""
                      onLoad={() => checkAllLoaded()}
                    />
                  </div>
                  <div className="portfolio-info">
                    <h4>Web Development</h4>
                    <p>Web Development</p>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PortfolioHome;
