import { Route, Routes } from "react-router";
import { useEffect } from "react";
import Root from "./routes/root";
import Resume from "./routes/resume/resume";
import Footer from "./components/footer";
import PortfolioHome from "./routes/portfolio/index";
import Photography from "./routes/portfolio/photography";
import Videography from "./routes/portfolio/videography";
import Privacy from "./routes/privacy";
import Articles from "./routes/articles";
import Article from "./routes/articles/article";
import { NAV } from "./constants/paths";
import NotFound from "./routes/not-found";
import useLoader from "./hooks/useLoader";
import WebDevelopment from "./routes/web-development";
import Project from "./routes/web-development/project";

const App = () => {
  const { setLoader, hideLoader } = useLoader();
  // setLoader();
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/assets/js/main.js";
    script.async = true;

    document.body.appendChild(script);
    // hideLoader();

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Routes>
        <Route path={NAV.HOME} element={<Root />} />
        <Route path={NAV.RESUME} element={<Resume />} />
        <Route path={NAV.PORTFOLIO} element={<PortfolioHome />} />
        <Route path={`${NAV.PORTFOLIO}/:album`} element={<Photography />} />
        <Route
          path={`${NAV.PORTFOLIO}/videography`}
          element={<Videography />}
        />
        <Route path={NAV.ARTICLES} element={<Articles />} />
        <Route path={`${NAV.ARTICLE}/:articleId`} element={<Article />} />
        <Route path={NAV.WEB_DEVELOPMENT} element={<WebDevelopment />} />
        <Route path={`${NAV.PROJECT}/:projectId`} element={<Project />} />
        <Route path={NAV.PRIVACY} element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
