import ken_limPic from "../assets/img/testimonials/ken_lim.jpg";
import andrewProfilePic from "../assets/img/testimonials/andrew_wong.jpeg";
import mooPic from "../assets/img/testimonials/moo.jpeg";
import yyPic from "../assets/img/testimonials/yy.jpg";
import andreaPic from "../assets/img/testimonials/andrea.jpg";
import tylerPic from "../assets/img/testimonials/tyler.jpg";
import ng_hoi_yewPic from "../assets/img/testimonials/ng_hoi_yew.jpg";
import dneshPic from "../assets/img/testimonials/dnesh.jpg";
import azlanPic from "../assets/img/testimonials/azlan.jpg";
import rakeshPic from "../assets/img/testimonials/rakesh.jpg";
import ka_kitPic from "../assets/img/testimonials/ka_kit.jpg";
import roloPic from "../assets/img/testimonials/rolo.jpg";

export const TESTIMONIES = [
  {
    name: "Rolo Khor",
    image: roloPic,
    purpose: "Personal Car Shoot",
    description:
      "This photographer knows what I want in car photography and manages to hit the points that I want. Stunning clarity in motion from the right angles. <br>Also willing to discuss and suggest places based on the subject of photography to achieve the best match. Trust him to tell you where to shoot.",
  },
  {
    name: "Ken Lim",
    image: ken_limPic,
    purpose: "Freelance Photographer",
    description:
      "Very professional photographer, he can fulfill all my requirement! Unique angle, nice colour tone. Highly recommended.",
  },
  {
    name: "Andrea Tommy Jok",
    image: andreaPic,
    purpose: "Graduation Shoot",
    description:
      "Super friendly guy who was super kind and understanding of my situation. Great in guiding and executing the style of shoot that I was going for. He goes all the way for pictures and was super open about shooting locations. Overall great experience.",
  },
  {
    name: "Andrew Wong",
    image: andrewProfilePic,
    purpose: "Cars and Coffee Malaysia",
    description:
      "Andrian is a very dedicated and focused professional photographer. If you task this man on a mission, rest assured he accomplishes it beyond your ordinary expectations. Keep up the good work bro.",
  },
  {
    name: "Moo Voon Hoe",
    image: mooPic,
    purpose: "Wedding Ceremony",
    description:
      "Andrian's dedication to our wedding day was truly unparalleled. From the intimate details to the grand celebrations, he masterfully seized every moment with an artistic flair. Andrian not only exceeded our expectations in terms of professionalism but also showcased a genuine passion for his craft. We were incredibly fortunate to have had him document our special day and would highly recommend him to anyone looking for a professional, skillful and authentic photographer.",
  },
  {
    name: "YY Yap",
    image: yyPic,
    purpose: "Surprise Proposal",
    description:
      "Friendly guy who i ask for photoshot in very short time period & he manage to squeeze his timing for my big day of proposal. Feel very thankful to him and the outcome of photo and qualities making good memories for us. Keep it up mateee 🫶🏻",
  },
  {
    name: "Tyler Yap",
    image: tylerPic,
    purpose: "Automotive Photography",
    description:
      "Amazing photos were taken even if it was done on site, pictures were sharp, angle and backgrounds were amazing. Quality is consistent and the resolution given in the full images was amazing with no complaints. Thanks to them I finally have amazing rolling shots of my car in Sepang and I can't thank them enough.",
  },
  {
    name: "Ng Hoi Yew",
    image: ng_hoi_yewPic,
    purpose: "Automotive Photography",
    description:
      "very happy with my photos gotten fron Tōge-Lifestyle. <br>super sharp and high res, high quality. Angle was perfect and things looks lively and able to feel the motion of it",
  },
  {
    name: "Dnesh Jothiahnantham",
    image: dneshPic,
    purpose: "Automotive Photography",
    description:
      "The photos were perfect. Photos were crisp clear and they are super high resolution. I would definitely recommend them for car shoots 😊",
  },
  {
    name: "Azlan SwiftLog ",
    image: azlanPic,
    purpose: "Automotive Photography",
    description:
      "Very sharp and emotion captured .. pictures dont lies .. thers a story that freeze in time .. keep up the good work brother",
  },
  {
    name: "Rakesh Kahan",
    image: rakeshPic,
    purpose: "Automotive Photography",
    description:
      "Very high resolution and sharp pictures, every picture was in focus, and the angles were on point. Definitely recommend them if you want to do car photoshoots",
  },
  {
    name: "Ka Kit Leong ",
    image: ka_kitPic,
    purpose: "Automotive Photography",
    description:
      "Taking my car to the track was an adrenaline rush, but Toge Lifestyle's photos capture the experience perfectly. Their expertise shines through in the crisp shots, from the car's details to the blur of motion that conveys pure speed. <br> They go beyond photography, using clever angles to make the car feel even faster and capturing the essence of a thrilling day. These aren't just pictures; they're bottled exhilaration and a permanent reminder of an unforgettable experience.",
  },
];
