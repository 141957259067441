import { Helmet } from "react-helmet";
import { NAV } from "../constants/paths";
import { PHOTO_CATEGORIES } from "../constants/titles";

interface HeaderType {
  isRoot?: boolean;
  isResume?: boolean;
  isPortfolio?: boolean;
  isArticles?: boolean;
}

const Header = ({
  isRoot = false,
  isResume = false,
  isPortfolio = false,
  isArticles = false,
}: HeaderType) => {
  return (
    <header
      id="header"
      className="fixed-top d-flex justify-content-center align-items-center header-transparent"
    >
      <Helmet>
        <meta
          property="og:image"
          content="http://andriantam.com/static/media/hero-bg.6baf0647f80bb1f6830a.jpg"
        />
        <meta
          name="description"
          content="andriantam | Photographer in Malaysia"
        />
        <meta
          name="keywords"
          content="andriantam, developer, photographer, programming, productions, video, website, portfolio, projects, web development, full stack software engineer, andrian tam, wedding, events"
        />
        <meta name="author" content="andriantam" />

        <meta property="fb:app_id" content="208771986349653" />
        <meta
          property="og:title"
          content="andriantam | Photographer in Malaysia"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="andriantam.com" />
        <meta
          property="og:description"
          content="andriantam | Photographer in Malaysia"
        />
      </Helmet>
      <nav id="navbar" className="navbar">
        <ul>
          <li>
            <a
              className={`nav-link ${isRoot && "scrollto active"}`}
              href={isRoot ? "#hero" : NAV.HOME}
            >
              Home
            </a>
          </li>
          {/* <li>
            <a
              className={`nav-link ${isRoot && "scrollto"}`}
              href={isRoot ? "#about" : NAV.ABOUT}
            >
              About
            </a>
          </li> */}
          <li>
            <a
              className={`nav-link ${isRoot && "scrollto"}`}
              href={isRoot ? "#services" : NAV.SERVICES}
            >
              Services
            </a>
          </li>
          <li>
            <a
              className={`nav-link ${isRoot && "scrollto"}`}
              href={isRoot ? "#testimonials" : NAV.REVIEWS}
            >
              Reviews
            </a>
          </li>
          <li>
            <a className={`nav-link ${isResume && "active"}`} href={NAV.RESUME}>
              Resume
            </a>
          </li>
          <li className="dropdown">
            <a
              className={`nav-link ${isPortfolio && "active"}`}
              href={NAV.PORTFOLIO}
            >
              <span>Portfolio</span> <i className="bi bi-chevron-down"></i>
            </a>
            <ul>
              <li className="dropdown">
                <a href={NAV.PORTFOLIO}>
                  <span>Photography</span>{" "}
                  <i className="bi bi-chevron-right"></i>
                </a>
                <ul>
                  <li>
                    <a href={`${NAV.PORTFOLIO}/${PHOTO_CATEGORIES.EVENTS.id}`}>
                      {PHOTO_CATEGORIES.EVENTS.title}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${NAV.PORTFOLIO}/${PHOTO_CATEGORIES.AUTOMOTIVE.id}`}
                    >
                      {PHOTO_CATEGORIES.AUTOMOTIVE.title}
                    </a>
                  </li>
                  <li>
                    <a href={`${NAV.PORTFOLIO}/${PHOTO_CATEGORIES.LOVE.id}`}>
                      {PHOTO_CATEGORIES.LOVE.title}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${NAV.PORTFOLIO}/${PHOTO_CATEGORIES.PRODUCTS.id}`}
                    >
                      {PHOTO_CATEGORIES.PRODUCTS.title}
                    </a>
                  </li>
                  <li>
                    <a href={`${NAV.PORTFOLIO}/${PHOTO_CATEGORIES.TRAVEL.id}`}>
                      {PHOTO_CATEGORIES.TRAVEL.title}
                    </a>
                  </li>
                  <li>
                    <a href={`${NAV.PORTFOLIO}/${PHOTO_CATEGORIES.FNB.id}`}>
                      {PHOTO_CATEGORIES.FNB.title}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href={`${NAV.PORTFOLIO}/videography`}>Videography</a>
              </li>
              <li>
                <a href={`${NAV.WEB_DEVELOPMENT}`}>Web Development</a>
              </li>
            </ul>
          </li>
          <li>
            <a
              className={`nav-link ${isArticles && "active"}`}
              href="/articles"
            >
              Articles
            </a>
          </li>
          <li>
            <a
              className={`nav-link ${isRoot && "scrollto"}`}
              href={isRoot ? "#contact" : NAV.CONTACT}
            >
              Contact
            </a>
          </li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </header>
  );
};

export default Header;
