import "./loader.style.scss";

const Loader = () => (
  <div id="loader">
    <div className="three-bounce">
      <div className="one"></div>
      <div className="two"></div>
      <div className="three"></div>
    </div>
  </div>
);

export default Loader;
