import axios from "axios-jsonp-pro";

export const getBehanceProjects = (): Promise<any> => {
  return axios
    .jsonp(
      `https://www.behance.net/v2/users/andriantam/projects?api_key=7NYwOTHUPTuESryTz19cFvXQHwOZtiJ9&`
    )
    .then((data) => data)
    .catch((err) => err);
};

export const getBehanceProject = (projectId: string): Promise<any> => {
  return axios
    .jsonp(
      `https://www.behance.net/v2/projects/${projectId}?api_key=7NYwOTHUPTuESryTz19cFvXQHwOZtiJ9`
    )
    .then((data) => data)
    .catch((err) => err);
};
